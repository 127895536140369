
	import { Component, Vue } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { actionsWrapper as SurveyActions, mutationsWrapper as SurveyMutations } from '@pixcap/ui-core/store/survey';
	import { NAMESPACE as SURVEY_NAMESPACE, ISurvey } from '@pixcap/ui-core/models/store/survey.interface';
	import SurveyStep from '@pixcap/ui-core/components/account/survey/SurveyStep.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import IconLoader from '@pixcap/ui-library/components/Icons/IconLoader.vue';
	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import SurveyProgressBar from '@pixcap/ui-core/components/account/survey/SurveyProgressBar.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import { FreebiesTypes } from '@pixcap/ui-core/models/store/app.interface';
	import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
	import { SHOULD_SHOW_REFERRAL_MODAL } from '@pixcap/ui-core/constants/auth.constants';

	@Component({
		name: 'SurveyModal',
		components: {
			SurveyProgressBar,
			Modal,
			IconLoader,
			SurveyStep,
			BodyText,
			HeadingText,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
				referralsInfo: (state: UserState) => state.referralsInfo,
			}),
			...mapState(SURVEY_NAMESPACE, {
				isLoadingSurvey: (state: ISurvey) => state.isLoadingSurvey,
				steps: (state: ISurvey) => state.questions,
			}),
		},
	})
	export default class SurveyModal extends Vue {
		image = `${_PIXCAP_ENV.ROOT_URL}/cdn/survey/welcome.webp`;

		referralsInfo: UserState['referralsInfo'];
		isLoadingSurvey: ISurvey['isLoadingSurvey'];
		steps: ISurvey['questions'];
		personalData: UserState['personalData'];
		currentStepIndex = 0;

		answers: { [questionId: string]: string | string[] } = {};

		get showNextButton() {
			return this.currentStepIndex > 1;
		}

		get showBackButton() {
			return this.currentStepIndex > 0;
		}

		get currentStep() {
			return this.steps[this.currentStepIndex];
		}

		get stepsCount() {
			return this.steps.length;
		}

		get stepAnswers() {
			return this.answers[this.currentStep?.questionId];
		}

		handleSelectAnswer(answer: string | string[]) {
			if (!this.currentStep) {
				return;
			}

			this.answers[this.currentStep.questionId] = answer;
			const question = this.currentStep;
			this.$pixcap.$mixpanel.people.set({
				[question.title]: answer,
			});
			this.handleNextStep();
		}

		private markSurveySubmitted() {
			const personalData = { ...this.personalData, user: { ...this.personalData.user, metadata: { ...this.personalData.user.metadata } } };
			personalData.user.metadata.surveySubmitted = true;
			UserMutations.updatePersonalData(this.$store, personalData);
		}

		handleNextStep() {
			if (this.currentStepIndex === this.steps.length - 1) {
				const requestParam = [];
				const answerId = Object.keys(this.answers);
				answerId.forEach((answer) => {
					requestParam.push({
						questionId: answer,
						answer: this.answers[answer],
					});
				});
				SurveyActions.submitSurveyAnswers(this.$store, requestParam);
				this.markSurveySubmitted();
				SurveyMutations.setShowOnboardingModal(this.$store, false);
				this.$pixcap.$services.serviceWorkerManager.postSurveyCompletedMessage();
				if (
					this.referralsInfo.rewards.length &&
					this.referralsInfo.rewards.find((reward) => reward.name === FreebiesTypes.WATERMARK_FREE) &&
					appUtilities.$services.localStorageManager.read(SHOULD_SHOW_REFERRAL_MODAL) !== 0
				) {
					UserMutations.setShowReferralModal(this.$store, true);
					appUtilities.$services.localStorageManager.write(SHOULD_SHOW_REFERRAL_MODAL, 0);
				}
			} else {
				this.currentStepIndex += 1;
			}
		}

		handlePreviousStep() {
			if (this.currentStepIndex > 0) {
				this.currentStepIndex -= 1;
			}
		}

		handleChangeNestedQuestion(questionId: string) {
			delete this.answers[questionId];
		}

		mounted() {
			SurveyActions.getSurveyData(this.$store);
		}
	}
