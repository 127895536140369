
	import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
	import { validateEmail } from '@pixcap/ui-core/utils/ValidationUtils';
	import { RESEND_EMAIL_TIMEOUT } from '@pixcap/ui-core/constants/app.constants';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';

	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import Header from '@pixcap/ui-core/components/account/authenticationModal/Header.vue';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'ForgotPassword',
		components: {
			BodyText,
			Header,
			InputWrapperAuth,
			Button,
		},
	})
	export default class ForgotPassword extends Mixins<FormMixin<{ email?: string }>>(FormMixin) {
		@Prop() readonly showBackButton: boolean;
		isSending = false;
		email = null;
		isSent = false;
		resendWaitingSeconds = 0;
		resendPasswordInterval;

		onBackClick() {
			this.$emit('handlePreviousStep');
		}

		@Watch('email')
		onEmailChange(email: string) {
			this.updateError(
				'email',
				validateEmail(email),
				// @ts-ignore
				this.$t ? this.$t('navbar.forgot_pw_form.error_email') : 'Email is invalid'
			);
		}

		get disableSend() {
			return !this.email || Object.keys(this.errorMsg).length > 0 || this.isSending || this.resendWaitingSeconds > 0;
		}

		initResendEmailTimeout() {
			if (this.resendPasswordInterval) {
				clearInterval(this.resendPasswordInterval);
			}
			this.resendWaitingSeconds = RESEND_EMAIL_TIMEOUT;
			this.resendPasswordInterval = setInterval(() => {
				this.resendWaitingSeconds -= 1;
			}, 1000);
		}

		get resendText() {
			if (this.resendWaitingSeconds > 0) return `${this.resendWaitingSeconds} second${this.resendWaitingSeconds > 1 ? 's' : ''} before retrying`;
			return 'Resend';
		}

		async forgotPassword() {
			if (this.disableSend) return;
			try {
				this.isSending = true;
				await AuthActions.forgetPassword(this.$store, { email: this.email });
				this.isSent = true;
				this.initResendEmailTimeout();
			} catch {
				$notify({ type: NotificationType.ERROR, title: 'Something went wrong please try again' });
			} finally {
				this.isSending = false;
			}
		}

		beforeDestroy() {
			if (this.resendPasswordInterval) {
				clearInterval(this.resendPasswordInterval);
			}
		}
	}
