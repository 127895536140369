
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconLargeArrowLeft from '@pixcap/ui-library/components/Icons/IconLargeArrowLeft.vue';

	@Component({
		name: 'SurveyProgressBar',
		components: {
			IconLargeArrowLeft,
			BodyText,
			Button,
		},
	})
	export default class SurveyProgressBar extends Vue {
		@Prop() readonly currentStepIndex: number;
		@Prop() readonly totalSteps: number;
		@Prop() readonly showBackButton: boolean;

		get progressFill() {
			return ((this.currentStepIndex + 1) / this.totalSteps) * 100;
		}

		onBackClick() {
			this.$emit('onBackClick');
		}
	}
