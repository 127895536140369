
	import { Component, Mixins, Prop } from 'vue-property-decorator';
	import SocialAuthMixin from '../common/SocialAuthMixin';
	import Header from './Header.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';

	const NUMBER_OF_DIGITS_IN_OTP_MAIL_VERIFICATION = 7;
	const NUMBER_OF_DIGITS_IN_OTP_RESET_PASSWORD = 6;
	@Component({
		name: 'OTPInput',
		components: {
			Header,
			Button,
		},
	})
	export default class OTPInput extends Mixins(SocialAuthMixin) {
		@Prop() readonly isValid: boolean;
		@Prop() readonly isLoading: boolean;
		@Prop({ default: true }) readonly withButton: boolean;
		@Prop() readonly otpType: string;

		inputValue = new Array(this.digits).map(() => null);
		$refs: {
			digitInput: HTMLInputElement[];
		};
		mounted() {
			this.$refs.digitInput[0].focus();
		}
		keydownHandler(index, e) {
			if (e.keyCode === 8 && e.target.value === '') {
				this.$refs.digitInput[Math.max(0, index - 1)].focus();
			}
		}

		get disableSubmit() {
			return this.otpCode.length !== this.digits;
		}

		get digits() {
			return this.otpType === 'reset-password' ? NUMBER_OF_DIGITS_IN_OTP_RESET_PASSWORD : NUMBER_OF_DIGITS_IN_OTP_MAIL_VERIFICATION;
		}

		get otpCode() {
			return this.inputValue.filter((value) => !!value).join('');
		}

		onInput(index: number, e) {
			const value = e.target.value;
			this.$set(this.inputValue, index, value ? value.toUpperCase() : value);
			const lastInputBox = index === this.digits - 1;
			const insertedContent = !!value;
			if (insertedContent && !lastInputBox) {
				this.$refs.digitInput[index + 1].focus();
			}
			if (!this.withButton && lastInputBox) {
				this.onComplete();
			}
		}
		onComplete() {
			this.$emit('onCompleteClick', this.otpCode);
		}
		onPaste(index, event) {
			if (index !== 0) {
				return;
			}

			const pasteText = event.clipboardData?.getData('Text');
			if (!pasteText) {
				return;
			}

			for (let index = 0; index < pasteText.length && index < this.inputValue.length; index++) {
				this.$set(this.inputValue, index, pasteText[index].toUpperCase());
			}
			this.$refs.digitInput[Math.min(pasteText.length - 1, this.inputValue.length - 1)].focus();
		}
	}
