
	import { Component, Watch, Mixins } from 'vue-property-decorator';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import { validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import { NAMESPACE as AUTH_NAMESPACE, ActionTypes as AuthActionTypes } from '@pixcap/ui-core/models/store/auth.interface';

	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import OtpInput from '@pixcap/ui-core/components/account/authenticationModal/OTPInput.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

	@Component({
		name: 'ResetPasswordForm',
		components: {
			BodyText,
			OtpInput,
			HeadingText,
			PixcapLogo,
			BaseModal,
			InputWrapperAuth,
			Button,
		},
	})
	export default class ResetPasswordForm extends Mixins<FormMixin<{ resetCode?: string; newPassword: string; rePassword?: string }>>(FormMixin) {
		displayVerifyCodeError = false;

		email = '';
		resetCode = '';
		newPassword = '';
		rePassword = '';

		get disableReset() {
			return !(this.resetCode && this.newPassword && this.rePassword === this.newPassword) || this.isSubmitting || Object.keys(this.errorMsg).length;
		}

		@Watch('newPassword')
		onNewPasswordChange(newPassword) {
			if (newPassword) this.handleValidatePassword(newPassword);
		}

		@Watch('rePassword')
		onReasswordChange(rePassword) {
			if (rePassword) this.handleValidateRePassword(rePassword);
		}

		@Watch('resetCode')
		onResetCodeChange() {
			if (this.errorMsg.resetCode) this.updateError('resetCode', true);
		}

		handleValidatePassword(password: string) {
			this.updateError(
				'newPassword',
				validatePassword(password),
				// @ts-ignore
				this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE
			);
			this.handleValidateRePassword(this.rePassword);
		}

		handleValidateRePassword(rePassword: string) {
			this.updateError(
				'rePassword',
				rePassword === this.newPassword,
				// @ts-ignore
				this.$t ? this.$t('common_elements.error_password_not_matched') : 'The password confirmation does not match.'
			);
		}

		handleOTP(value) {
			this.resetCode = value;
		}

		close() {
			UserMutations.showResetPasswordModal(this.$store, false);
		}

		resetPassword() {
			if (this.disableReset) return;
			this.$store
				.dispatch(
					`${AUTH_NAMESPACE}/${AuthActionTypes.RESET_PASSWORD}`,
					{
						email: this.email,
						confirmationCode: this.resetCode,
						password: this.newPassword,
					},
					{ root: true }
				)
				.then(() => {
					if (this.errorMsg.resetCode) this.updateError('resetCode', true);
					$notify({
						type: NotificationType.SUCCESS,
						title: 'Password has been reset',
						duration: 3000,
					});
					window.location.href = window.location.origin;
				})
				.catch(() => {
					this.updateError('resetCode', false, 'Entered code is not valid, try again');
					this.displayVerifyCodeError = true;
				});
		}
		mounted() {
			const $route = this.$route;
			const user = $route.query.user as string;
			if (user) this.email = user;
		}
	}
