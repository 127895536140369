
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ISurvey, NAMESPACE as SURVEY_NAMESPACE, Question } from '@pixcap/ui-core/models/store/survey.interface';

	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import { mapState } from 'vuex';
	import IconCircleCheck from '@pixcap/ui-library/components/Icons/IconCircleCheck.vue';
	import { mutationsWrapper as SurveyMutations } from '@pixcap/ui-core/store/survey';

	@Component({
		name: 'SurveyStep',
		components: {
			IconCircleCheck,
			BodyText,
			Button,
		},
		computed: {
			...mapState(SURVEY_NAMESPACE, {
				steps: (state: ISurvey) => state.questions,
			}),
		},
	})
	export default class SurveyStep extends Vue {
		@Prop() readonly step: ISurvey['questions'][number];
		@Prop() readonly stepIndex: number;
		@Prop() readonly stepAnswered: string | string[];
		@Prop() readonly showBackButton: boolean;
		@Prop() readonly showNextButton: boolean;

		steps: ISurvey['questions'];

		answers: any = [];
		isLoading: boolean = false;

		get isDisableSubmitButton() {
			if (this.isTopicSelection) return this.answers.length <= 2;
			return this.answers.length === 0;
		}

		get isLastStep() {
			return this.stepIndex + 1 === this.steps.length;
		}

		get isTopicSelection() {
			return false;
		}

		get isFourGridLayout() {
			return !this.isTopicSelection && this.step.options.length > 6;
		}

		onSelectAnswer(answer) {
			// Check if answer have nested question
			if (answer.questions?.length > 0) {
				const populatedQuestions = [...this.steps];
				populatedQuestions.splice(this.stepIndex + 1, 0, answer.questions[0]);
				SurveyMutations.setQuestionsList(this.$store, populatedQuestions);
				this.$emit('changeNestedQuestion', answer.questions[0].questionId);
				this.$emit('onSelectAnswer', answer.name);
			} else {
				if (!this.step.isMultiSelect) {
					if (this.isLastStep) {
						this.answers = answer.name;
					} else this.$emit('onSelectAnswer', answer.name);
				} else {
					if (this.answers.includes(answer.name)) {
						this.answers = this.answers.filter((item) => item !== answer.name);
					} else this.answers.push(answer.name);
				}
			}
		}

		delay(time) {
			return new Promise((resolve) => setTimeout(resolve, time));
		}

		async submit() {
			if (this.isLastStep) {
				this.isLoading = true;
				await this.delay(1400);
			}
			this.$emit('onSelectAnswer', this.answers);
		}

		mounted() {
			if (this.stepAnswered?.length > 0) this.answers = this.stepAnswered;
		}
	}
