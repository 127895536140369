
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'Header',
		components: { BodyText, HeadingText },
	})
	export default class Header extends Vue {
		@Prop({ default: 'medium' }) readonly size: 'lg' | 'medium';
	}
