
	import { Component, Mixins, Prop } from 'vue-property-decorator';
	import Header from '@pixcap/ui-core/components/account/authenticationModal/Header.vue';
	import SocialAuthMixin from '@pixcap/ui-core/components/account/common/SocialAuthMixin';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	import IconFacebook from '@pixcap/ui-library/components/Icons/IconFacebook.vue';
	import IconGoogle from '@pixcap/ui-library/components/Icons/IconGoogle.vue';
	import EmailLogin from '@pixcap/ui-core/components/account/authenticationModal/EmailLogin.vue';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';

	@Component({
		name: 'LoginForm',
		components: {
			EmailLogin,
			Header,
			BodyText,
			IconGoogle,
			IconFacebook,
		},
		computed: {},
	})
	export default class LoginForm extends Mixins(SocialAuthMixin) {
		@Prop() readonly email: string;
		@Prop() readonly password: string;

		referrerName: string = null;

		get isReferralFlow() {
			return !!this.referrerName;
		}

		onForgotPasswordClicked() {
			this.$emit('handleNextStep', 'FORGOT_PASSWORD');
		}

		onEmailChange(value: string) {
			this.$emit('onEmailChange', value);
		}
		onPasswordChange(value: string) {
			this.$emit('onPasswordChange', value);
		}

		onSignUp() {
			const $route = this.$route;
			const referralCode = $route.query.referral as string;
			if (referralCode) {
				this.$pixcap.$mixpanel.track('Referral: Registered via referral code', { referralCode, referralName: this.referrerName });
			}
			this.$emit('handleNextStep', 'EMAIL_VERIFICATION');
		}

		onSignInSuccessfully() {
			this.$emit('onSignInSuccessfully');
		}

		async mounted() {
			const $route = this.$route;
			const referralCode = $route.query.referral as string;
			if (referralCode) {
				const { name } = await AuthActions.validateReferralCode(this.$store);
				this.referrerName = name;
			}
		}
	}
