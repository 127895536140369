
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import Header from '@pixcap/ui-core/components/account/authenticationModal/Header.vue';
	import OtpInput from '@pixcap/ui-core/components/account/authenticationModal/OTPInput.vue';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import ResendVerificationButton from '@pixcap/ui-core/components/account/ResendVerificationButton.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';

	@Component({
		name: 'VerifyEmail',
		components: {
			BodyText,
			Header,
			OtpInput,
			Button,
			ResendVerificationButton,
		},
	})
	export default class VerifyEmail extends Vue {
		@Prop() readonly email: string;
		@Prop() readonly password: string;
		@Prop() readonly showBackButton: boolean;
		displayVerifyEmailError = false;
		isVerifyingEmail = false;

		async onVerifyEmail(otpCode: string) {
			this.isVerifyingEmail = true;
			try {
				const response = await AuthActions.verifyEmail(this.$store, { email: this.email, verificationKey: otpCode });
				AuthActions.store(this.$store, response as any);
				this.$emit('onSignInSuccessfully');
			} catch {
				this.displayVerifyEmailError = true;
			}
			this.isVerifyingEmail = false;
		}
	}
