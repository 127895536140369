
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import ChangeAvatar from '@pixcap/ui-core/components/account/ChangeAvatar.vue';
	import { User } from '@pixcap/ui-core/models/store/user.interface';
	import { actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import InputWrapper from '@pixcap/ui-library/components/InputWrapper.vue';

	@Component({
		name: 'AccountSettingForm',
		components: {
			Button,
			InputWrapper,
			ChangeAvatar,
		},
	})
	export default class AccountSettingForm extends Vue {
		@Prop() readonly user: User;

		bio = '';
		displayName = '';
		isSubmitting = false;

		get disableSubmit() {
			const sanitized = this.sanitizeHtml(this.bio);
			return sanitized.length > 200 || this.isSubmitting || (this.bio == this.user.bio && this.displayName == this.user.name);
		}

		async saveBio() {
			if (this.isSubmitting) return;
			this.isSubmitting = true;
			try {
				const updateDto: { bio?: string; displayName?: string } = {};
				const sanitizedHtml = this.sanitizeHtml(this.bio);
				if (sanitizedHtml != this.user.bio) {
					updateDto.bio = sanitizedHtml;
				}
				if (this.displayName != this.user.name) {
					updateDto.displayName = this.displayName;
				}
				await UserActions.updatePersonalData(this.$store, updateDto);
			} finally {
				this.isSubmitting = false;
			}
		}

		sanitizeHtml(htmlText) {
			const parser = new DOMParser();
			const parsedHtml = parser.parseFromString(htmlText, 'text/html');
			return parsedHtml.body.textContent || '';
		}

		mounted() {
			this.bio = this.user.bio || '';
			this.displayName = this.user.name || '';
		}
	}
