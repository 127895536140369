
	import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
	import Header from '@pixcap/ui-core/components/account/authenticationModal/Header.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import InputWrapperAuth from '@pixcap/ui-core/components/account/common/InputWrapperAuth.vue';
	import FormMixin from '@pixcap/ui-core/components/account/common/FormMixin';
	import { validateEmail, validatePassword } from '@pixcap/ui-core/utils/ValidationUtils';
	import { VALIDATE_PASSWORD_MESSAGE } from '@pixcap/ui-core/constants/validation.constants';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import { $notify } from '@pixcap/ui-core/helpers/notification';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
	import { REFERRAL_CODE_KEY } from '@pixcap/ui-core/constants/auth.constants';

	@Component({
		name: 'EmailLogin',
		components: {
			Header,
			BodyText,
			InputWrapperAuth,
			Button,
		},
		computed: {},
	})
	export default class EmailLogin extends Mixins<FormMixin<{ password?: string; email?: string }>>(FormMixin) {
		@Prop() readonly email: string;
		@Prop() readonly password: string;
		@Prop() readonly showBackButton: boolean;
		isLoading = false;

		get disableSubmit() {
			return !(this.email && this.password) || this.isLoading;
		}

		get referralCode() {
			return this.$route.query.referral || appUtilities.$services.localStorageManager.read(REFERRAL_CODE_KEY);
		}

		onForgotPasswordClicked() {
			this.$emit('onForgotPasswordClicked');
		}
		onEmailChange(value: string) {
			this.$emit('onEmailChange', value);
		}
		onPasswordChange(value: string) {
			this.$emit('onPasswordChange', value);
		}

		@Watch('email')
		handleValidateEmail(email: string) {
			if (email) {
				this.updateError(
					'email',
					validateEmail(email),
					// @ts-ignore
					this.$t ? this.$t('navbar.login_form.email_login.error_email') : 'Email is invalid'
				);
			}
		}

		@Watch('password')
		handleValidatePassword(password: string) {
			this.updateError(
				'password',
				validatePassword(password),
				// @ts-ignore
				this.$t ? (this.$t('common_elements.error_password_length') as string) : VALIDATE_PASSWORD_MESSAGE
			);
		}

		async onSubmitClicked() {
			if (this.disableSubmit) {
				return;
			}
			this.isLoading = true;

			try {
				const response = await AuthActions.signIn(this.$store, { email: this.email, password: this.password });
				AuthActions.store(this.$store, response as any);
				this.$emit('onSignInSuccessfully');
				if (this.referralCode) {
					this.$router.replace({ query: null });
					appUtilities.$services.localStorageManager.remove(REFERRAL_CODE_KEY);
					$notify({ type: NotificationType.INFO, title: 'You already have a Pixcap account. The link you used is only valid for new users.' });
				}
			} catch (error) {
				const errorCode = error?.data?.errorCode;
				switch (errorCode) {
					case 'E02_02_004':
					case 'E02_02_001': {
						this.updateError(
							'password',
							false,
							// @ts-ignore
							this.$t ? this.$t('navbar.login_form.email_login.error_E02_02_001') : 'Email or password is incorrect.'
						);
						break;
					}
					case 'E02_02_002': {
						// @ts-ignore
						this.updateError('password', false, this.$t ? this.$t('navbar.login_form.email_login.error_E02_02_002') : 'This email is already in use');
						break;
					}
					case 'E02_02_003': {
						try {
							await AuthActions.signUp(this.$store, { password: this.password, email: this.email });
							this.$emit('onSignUp');
							break;
						} catch {
							$notify({
								type: NotificationType.ERROR,
								// @ts-ignore
								title: this.$t ? this.$t('navbar.login_form.email_login.error_E02_02_003') : 'Something went wrong please try again',
							});
						}
					}
					default: {
						$notify({
							type: NotificationType.ERROR,
							// @ts-ignore
							title: this.$t ? this.$t('navbar.login_form.email_login.error_default') : 'Failed to login please try again',
						});
					}
				}
			} finally {
				this.isLoading = false;
			}
		}
	}
