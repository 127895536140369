
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { actionsWrapper as UserActions, mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

	import LoginForm from '@pixcap/ui-core/components/account/authenticationModal/LoginForm.vue';
	import VerifyEmail from '@pixcap/ui-core/components/account/authenticationModal/VerifyEmail.vue';
	import ForgotPassword from '@pixcap/ui-core/components/account/authenticationModal/ForgotPassword.vue';

	import IconLoader from '@pixcap/ui-library/components/Icons/IconLoader.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';

	export const STEP_DEFINITION = {
		LOGIN_FORM: LoginForm,
		FORGOT_PASSWORD: ForgotPassword,
		EMAIL_VERIFICATION: VerifyEmail,
	};
	@Component({
		name: 'AuthenticationModal',
		components: {
			PixcapLogo,
			BaseModal,
			LoginForm,
			IconLoader,
			ForgotPassword,
			VerifyEmail,
		},
		computed: {},
	})
	export default class AuthenticationModal extends Vue {
		historyStack: any = [STEP_DEFINITION.LOGIN_FORM];

		email = '';
		password = '';
		onEmailChange(value: string) {
			this.email = value;
		}
		onPasswordChange(value: string) {
			this.password = value;
		}

		get defaultImage() {
			if (window && window.innerWidth <= 1025) return `${_PIXCAP_ENV.ROOT_URL}/cdn/app/authentication/thumbnail-mb.webp`;
			return `${_PIXCAP_ENV.ROOT_URL}/cdn/app/authentication/thumbnail.webp`;
		}

		get currentStep() {
			return this.historyStack[this.historyStack.length - 1];
		}

		handleNextStep(step: keyof typeof STEP_DEFINITION) {
			this.historyStack.push(STEP_DEFINITION[step]);
		}

		handlePreviousStep() {
			if (this.historyStack.length > 1) {
				this.historyStack.pop();
			}
		}

		onSignInSuccessfully() {
			if (window.parent.origin == window.origin) {
				window.parent.postMessage('login_successfully', '*');
			}
			const onAuthenticated = this.$pixcap.$services.authService.getOnAuthenticated();
			UserActions.fetchPersonalData(this.$store).then(({ subscriptionInfo }) => {
				if (onAuthenticated) {
					onAuthenticated(subscriptionInfo);
				}
			});
			try {
				const redirectPath = new URLSearchParams(window.location.search).get('redirect');
				if (redirectPath) window.location.href = redirectPath;
			} catch {}

			this.$emit('onSignInSuccessfully');
			this.close();
		}

		close() {
			this.$pixcap.$services.authService.setOnAuthenticated(undefined);
			UserMutations.showAuthenticationModal(this.$store, false);
			if (window.parent.origin == window.origin) {
				window.parent.postMessage('close_authentication_prompt', '*');

				// Reset states
				this.historyStack = [STEP_DEFINITION.LOGIN_FORM];
				this.email = '';
				this.password = '';
			}

			this.$emit('onClose');
		}
	}
